<template>
  <div>
    <h3 class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2 text-gray-800">
      TRANSFERABILITY OF CREDITS DISCLOSURE
    </h3>
    <hr class="border-red-300 mt-5">
    <validation-observer ref="formTNDisclosure" v-slot="{handleSubmit}">
      <p class="text-gray-800 text-md font-semibold mt-2">
        {{ disclosure }}
      </p>
      <div class="flex justify-around mt-2 pt-6" style="border-bottom: 1px solid black;">
        <div class="text-center self-stretch" >
          <div class="bg-yellow-300 cursor-pointer p-4"
            @click="onSign"
            v-if="!studentSignature"
          >
            <p class="cursor-pointer text-xs md:text-base" > Sign </p>
            <zondicon
            icon="arrow-thick-down"
            class="h-5 ml-1"/>
          </div>
          <div  v-if="studentSignature">
            <p class="student-signature text-xl md:text-2xl"> {{ studentSignature }} </p>
          </div>

        </div>
        <div class="text-center self-end">
          <p class="text-xs md:text-base">{{ new Date() | moment("dddd, MMMM Do YYYY") }}</p>
        </div>
      </div>
      <validation-provider name="studentSignature" v-slot="{ errors }">
        <input type="hidden" v-model="studentSignature">
        <p class="mt-1 ml-1 text-red-500 text-xs md:text-sm font-semibold italic">{{ errors[0]}}</p>
      </validation-provider>
      <next-back-buttons v-on:next="handleSubmit(onSubmit)" v-on:back="back">
      </next-back-buttons>
    </validation-observer>

  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  import NextBackButtons from './Elements/NextBackButtons.vue';
  import { InputFacade } from 'vue-input-facade';
  import { ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
  import { ValidationObserver } from 'vee-validate';
  import Zondicon from 'vue-zondicons';

  export default {
    data: () => ({
      disclosure: `Credits earned at Phlebotomy Training Specialists may not transfer to another educational institution. Credits/contact hours earned at another educational institution may not be accepted by Phlebotomy Training Specialists. You should obtain confirmation that Phlebotomy Training Specialists will accept any credits you have earned at another educational institution before you execute an enrollment contract or agreement. You should also contact any educational institutions that you may want to transfer credits earned at Phlebotomy Training Specialist to determine if such institutions will accept credits earned at Phlebotomy Training Specialists prior to executing an enrollment contract or agreement. The ability to transfer credits from Phlebotomy Training Specialists to another educational institution may be very limited. Your credits may not transfer and you may have to repeat courses previously taken at Phlebotomy Training Specialists if you enroll in another educational institution. You should never assume that credits will transfer to or from any educational institution. It is highly recommended and you are advised to make certain that you know the transfer of credit policy of Phlebotomy Training Specialists and of any other educational institutions you may in the future want to transfer the credits earned at Phlebotomy Training Specialists before you execute an enrollment contract or agreement.`,
      fullNameSignature: '',
    }),
    components:{
      NextBackButtons,
      'zondicon': Zondicon,
      'input-facade': InputFacade,
      'validation-provider': ValidationProvider,
      'validation-observer' : ValidationObserver
    },
    mounted(){
      this.fullNameSignature = `${this.name} ${this.lastName}`;
      // console.log(this.studentSignature);
      // if(this.studentSignature){
      //   this.studentSignature = `${this.name} ${this.lastName}`;
      // }
      //this.studentSignature = `${this.name} ${this.lastName}`;
    },
    computed:{
      ...mapState('formData',['tnChecklist','name','lastName']),
      getInitials(){
        let fullName = this.name + " " + this.lastName;
        return fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).join('').toUpperCase();
      },
      studentSignature: {
        get(){
          return this.tnChecklist.studentDisclosureSignature;
        },
        set(value){
          this.updateTNChecklistItem({
            field: 'studentDisclosureSignature',
            value,
          });
        },
      },
    },
    methods:{
      ...mapMutations('formData',['updateTNChecklistItem']),
      onCheckItem(field, value){
        this.updateTNChecklistItem({ field, value });
      },
      onSign(){
        this.studentSignature = this.fullNameSignature;
        this.studentSignatureSign = true;
      },
      onSignInitials(field){
        const fieldSigned = `${field}Sign`
        // console.log(fieldSigned);
        this[field] = true;
        this[fieldSigned] = true;
      },
      returnModel(model){
        //this.$nextTick(() => {
          return this[model];
        //})

      },
      async onSubmit() {

        try {
          const { fields } = this.$refs.formTNDisclosure;

          for (const field in fields) {
            const value = this.tnChecklist[field];
            // console.log(value);
            if(!value){
              await this.$refs.formTNChecklist.setErrors({
                [field]: ['This field must be signed'],
              });
              return;
            }
          }

          this.$emit('next');


        } catch (error) {
          console.log(error);
        }

      },
      back(){
        this.$emit('back');
      },
    }
  }
</script>

<style scoped>
  p.student-signature{

    font-family: "Cedarville Cursive";


  }
  span.student-signature{
    font-family: "Cedarville Cursive";
    /*font-size:12pt;*/
  }
  .cursor-pointer{
    cursor: pointer;
  }
</style>