var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2",class:{
      ..._vm.formCssClasses('label'),
    }},[_vm._v(" Student Registration Information ")]),_c('hr',{staticClass:"mt-5",class:{
      ..._vm.formCssClasses('hr'),
    }}),_c('div',{staticClass:"md:flex mt-8 mb-2"},[_c('div',{staticClass:"md:flex-1 md:mr-4 pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" Email ")]),_c('input',{staticClass:"sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.email,
          'border-solid border-4 border-light-blue-500':
            _vm.isTranscriptReceived || _vm.isApproved,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"Email","disabled":_vm.isTranscriptReceived || _vm.isApproved},domProps:{"value":_vm.email},on:{"input":function($event){return _vm.onInput('email', 'email', $event)},"blur":_vm.onBlurEmail}}),_c('input',{staticClass:"hidden sm:block px-3 py-4 relative bg-white rounded text-base shadow-md outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.email,
          'border-solid border-4 border-light-blue-500':
            _vm.isTranscriptReceived || _vm.isApproved,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"example@gmail.com","disabled":_vm.isTranscriptReceived || _vm.isApproved},domProps:{"value":_vm.email},on:{"input":function($event){return _vm.onInput('email', 'email', $event)},"blur":_vm.onBlurEmail}}),(_vm.errors.email)?_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[(_vm.errors.email == 'exists')?_c('span',[_vm._v(" It looks like you already have an account with us. Please call "+_vm._s(_vm.supportPhoneNumber)+" to register to a new class. ")]):(_vm.errors.email == 'can_register')?_c('span',[_vm._v(" YOU ALREADY HAVE AN ACCOUNT! "),_c('br'),_vm._v(" Please a "),_c('a',{staticClass:"underline",attrs:{"href":`${_vm.studentPortalUrl}/student/checkout?c=${_vm.id}`}},[_vm._v("click here")]),_vm._v(" to access your student portal to register for the class ")]):_c('span',[_vm._v(" "+_vm._s(_vm.errors.email)+" ")])]):(_vm.notices.email)?_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic",staticStyle:{"color":"#EE930E"}},[(_vm.notices.email == 'suggestion')?_c('span',[_vm._v(" Did you mean: "+_vm._s(_vm.suggestion)+". ")]):(_vm.notices.email == 'Invalid')?_c('span',[_vm._v(" Please verify your email address ")]):(_vm.notices.email == 'Risky')?_c('span',[_vm._v(" If you don’t receive an email following registration, please check your junk and spam folders. ")]):_vm._e()]):_vm._e()]),_vm._m(0)]),_c('div',{staticClass:"md:flex md:mt-3 sm:mt-6"},[_c('div',{staticClass:"md:flex-1 mb-4 md:mr-4 pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" Name ")]),_c('input',{staticClass:"sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.name,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"Name"},domProps:{"value":_vm.name},on:{"input":function($event){return _vm.onInput('name', 'name', $event)},"blur":_vm.onBlurFirstName}}),_c('input',{staticClass:"hidden sm:block px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.name,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"John"},domProps:{"value":_vm.name},on:{"input":function($event){return _vm.onInput('name', 'name', $event)},"blur":_vm.onBlurFirstName}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.name)+" ")])]),_c('div',{staticClass:"md:flex-1 mb-4 pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" Last Name ")]),_c('input',{staticClass:"sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.lastName,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"Last Name"},domProps:{"value":_vm.lastName},on:{"input":function($event){return _vm.onInput('lastName', 'last name', $event)},"blur":_vm.onBlurLastName}}),_c('input',{staticClass:"hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.lastName,
          ..._vm.formCssClasses('input'),
        },attrs:{"type":"text","placeholder":"Doe"},domProps:{"value":_vm.lastName},on:{"input":function($event){return _vm.onInput('lastName', 'last name', $event)},"blur":_vm.onBlurLastName}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.lastName)+" ")])])]),_c('p',{staticClass:"block md:text-base font-normal",class:{
      ..._vm.formCssClasses('label'),
    }},[_vm._v(" This is EXACTLY as your name will appear on your Certificate ")]),_c('div',{staticClass:"md:flex mt-6 sm:mt-10"},[_c('div',{staticClass:"md:flex-1 mb-3 md:mr-4 pt-3 md:pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" Phone Number ")]),_c('input-facade',{staticClass:"sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.number,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":"(###) ### - ####","type":"text","value":_vm.number,"placeholder":"Phone Number"},on:{"blur":_vm.onBlurPhone},nativeOn:{"input":function($event){return _vm.onInput('number', 'number', $event)}}}),_c('input-facade',{staticClass:"hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.number,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":"(###) ### - ####","type":"text","value":_vm.number,"placeholder":"(XXX) XXX - XXXX"},on:{"blur":_vm.onBlurPhone},nativeOn:{"input":function($event){return _vm.onInput('number', 'number', $event)}}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.number)+" ")])],1),_c('div',{staticClass:"md:flex-1 mb-3 pt-3 md:pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" DOB ")]),_c('input-facade',{staticClass:"sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.dob,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":"##/##/####","value":_vm.dob,"type":"text","placeholder":"DOB (MM/DD/YYYY)"},nativeOn:{"input":function($event){return _vm.onInput('dob', 'DOB', $event)}}}),_c('input-facade',{staticClass:"hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.dob,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":"##/##/####","value":_vm.dob,"type":"text","placeholder":"MM/DD/YYYY"},nativeOn:{"input":function($event){return _vm.onInput('dob', 'DOB', $event)}}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.dob)+" ")])],1),(false)?_c('div',{staticClass:"md:w-3/12 mb-3 pt-3 md:pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" SSN "),(_vm.courseState.ssnType === 'last4')?_c('a',{staticClass:"text-sm text-pink-800 font-normal"},[_vm._v("(last 4)")]):_vm._e()]),_c('input-facade',{staticClass:"sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.ssn,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":_vm.courseState.ssnType === 'full' ? '###-##-####' : '####',"type":"text","value":_vm.ssn,"placeholder":_vm.courseState.ssnType === 'full' ? 'SSN' : 'SSN  (Last 4)'},nativeOn:{"input":function($event){return _vm.onInput('ssn', 'SSN', $event)}}}),_c('input-facade',{staticClass:"hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
          'border-2 border-red-500': _vm.errors.ssn,
          ..._vm.formCssClasses('input'),
        },attrs:{"mask":_vm.courseState.ssnType === 'full' ? '###-##-####' : '####',"type":"text","value":_vm.ssn,"placeholder":_vm.courseState.ssnType === 'full' ? 'XXX-XX-XXXX' : 'XXXX'},nativeOn:{"input":function($event){return _vm.onInput('ssn', 'SSN', $event)}}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.ssn)+" ")])],1):_vm._e()]),_c('div',{staticClass:"flex"},[(false)?_c('div',{staticClass:"w-full md:w-5/5 mt-3 md:mt-0"},[_c('p',{staticClass:"block md:text-xl font-normal mb-1 md:mb-3",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" Upload your High School Diploma or Equivalent ")]),_c('div',[_c('div',[_c('button',{staticClass:"text-base shadow w-full md:w-3/5 py-4",class:{
              ..._vm.formCssClasses('upload_button'),
            },on:{"click":_vm.onTriggerInputFile}},[_vm._v(" Upload ")]),_c('input',{ref:"graduationDocInputFile",staticClass:"hidden",attrs:{"type":"file"},on:{"change":_vm.onGraduationDocChange}})]),_c('div',[_c('p',{staticClass:"text-red-900 font-normal md:mb-3",class:{
              ..._vm.formCssClasses('paragraph'),
            }},[_vm._v(" "+_vm._s(_vm.graduationDocName)+" ")])])]),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.graduationDoc)+" ")])]):_vm._e()]),(_vm.cprCourses.length > 0)?_c('cpr-courses',{attrs:{"error":_vm.errors.cprCourses,"on-clear-error":() => {
        _vm.errors.cprCourses = ``;
      }}}):_vm._e(),(_vm.cprCourse)?_c('div',[_c('p',{staticClass:"block text-red-900 md:text-2xl font-normal md:mb-3"},[_vm._v(" Your mandatory class orientation will be on "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.cprCourseStartDate))])])]):_vm._e(),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"inline-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.age),expression:"age"}],staticClass:"form-checkbox h-5 w-5",class:{
          ..._vm.formCssClasses('radio_button'),
        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.age)?_vm._i(_vm.age,null)>-1:(_vm.age)},on:{"change":function($event){var $$a=_vm.age,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.age=$$a.concat([$$v]))}else{$$i>-1&&(_vm.age=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.age=$$c}}}}),_c('span',{staticClass:"ml-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" I attest that I am at least 18 years old. ")])])]),(_vm.graduationDocRequired)?_c('div',{staticClass:"mt-2"},[_c('label',{staticClass:"inline-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gradDoc),expression:"gradDoc"}],staticClass:"form-checkbox h-5 w-5",class:{
          ..._vm.formCssClasses('radio_button'),
        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.gradDoc)?_vm._i(_vm.gradDoc,null)>-1:(_vm.gradDoc)},on:{"change":function($event){var $$a=_vm.gradDoc,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.gradDoc=$$a.concat([$$v]))}else{$$i>-1&&(_vm.gradDoc=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.gradDoc=$$c}}}}),_c('span',{staticClass:"ml-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" I attest that I have completed Highschool, GED, or equivalent. ")])])]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('label',{staticClass:"inline-flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondQuestion),expression:"secondQuestion"}],staticClass:"form-checkbox h-5 w-5",class:{
          ..._vm.formCssClasses('radio_button'),
        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.secondQuestion)?_vm._i(_vm.secondQuestion,null)>-1:(_vm.secondQuestion)},on:{"change":function($event){var $$a=_vm.secondQuestion,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.secondQuestion=$$a.concat([$$v]))}else{$$i>-1&&(_vm.secondQuestion=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.secondQuestion=$$c}}}}),_c('span',{staticClass:"ml-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" I am proficient in reading and writing the English Language. ")])])]),_c('div',{staticClass:"mt-2"},[_c('label',{staticClass:"inline-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.termsOfService),expression:"termsOfService"}],staticClass:"form-checkbox h-5 w-5",class:{
          ..._vm.formCssClasses('radio_button'),
        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.termsOfService)?_vm._i(_vm.termsOfService,null)>-1:(_vm.termsOfService)},on:{"change":function($event){var $$a=_vm.termsOfService,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.termsOfService=$$a.concat([$$v]))}else{$$i>-1&&(_vm.termsOfService=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.termsOfService=$$c}}}}),_c('span',{staticClass:"ml-2",class:{
          ..._vm.formCssClasses('label'),
        }},[_vm._v(" I have read the "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline",attrs:{"target":"_blank","href":_vm.termsOfServiceLink}},[_vm._v(" enrollment agreement ")]),_vm._v(" , "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline",attrs:{"target":"_blank","href":_vm.privacyPolicyUrl}},[_vm._v(" privacy policy ")]),(_vm.state === 'TN')?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.state === 'TN')?_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline",attrs:{"target":"_blank","href":_vm.courseCatalogUrl}},[_vm._v(" course catalog ")]):_vm._e(),(_vm.state === 'NV')?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.state === 'NV')?_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline",attrs:{"target":"_blank","href":_vm.nvCourseCatalogUrl}},[_vm._v(" course catalog ")]):_vm._e(),_vm._v(" and agree to the terms listed therein. ")])])]),_c('div',[_c('h6',{staticClass:"text-base md:text-lg font-semibold mt-8 mb-4",class:{
        ..._vm.formCssClasses('label'),
      }},[_vm._v(" How did you hear about us? ")]),_c('div',{staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0"},[_c('div',{staticClass:"relative"},[_c('select',{staticClass:"block appearance-none w-full bg-white py-4 px-4 pr-8 rounded leading-tight focus:outline-none shadow focus:bg-white focus:border-red-900",class:{
              'border-2 border-red-500': _vm.errors.referredBy,
              ..._vm.formCssClasses('input'),
            },domProps:{"value":_vm.referredBy},on:{"change":function($event){return _vm.onChange('referredBy', $event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select one")]),_vm._l((_vm.referralOpts),function(opt){return _c('option',{key:opt.value,domProps:{"value":opt.value}},[_vm._v(" "+_vm._s(opt.text)+" ")])})],2),_c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"},[_c('svg',{staticClass:"fill-current text-red-900 h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])]),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.referredBy)+" ")])]),(_vm.referredBy === 'other')?_c('div',{staticClass:"w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0"},[_c('label',{staticClass:"text-red-900 text-md ml-2 mb-2",class:{
            ..._vm.formCssClasses('label'),
          }},[_vm._v(" Specify below ")]),_c('input',{staticClass:"px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full mt-4",class:{
            'border-2 border-red-500': _vm.errors.commentReferredBy,
            ..._vm.formCssClasses('input'),
          },attrs:{"type":"text","placeholder":"Please specify"},domProps:{"value":_vm.commentReferredBy},on:{"input":function($event){return _vm.onInput('commentReferredBy', 'Comment Referred By', $event)}}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.commentReferredBy)+" ")])]):_vm._e()])]),_c('next-back-buttons',{attrs:{"next-blocked":!_vm.ready},on:{"next":_vm.onSubmit,"back":_vm.back}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:flex-1"},[_c('p',{staticClass:"text-gray-500 text-xs p-2 md:mt-5"},[_vm._v(" By entering your contact info you agree to receive correspondence from PTS. ")])])
}]

export { render, staticRenderFns }