<template>
  <div>
    <div v-if="isAvailableDiscountCoupon" class="-mx-8">
      <CourseCoupon />
    </div>

    <!-- Table Toggle Button -->
    <div
      @click="onOpen"
      :class="[showMobileCheckoutBreakdown ? 'font-bold' : '']"
      class="-mx-8 border-grey-200 border-2 mt-4 text-gray-700 bg-gray-100"
    >
      <div class="flex text-lg mx-8 my-4">
        <div class="flex-1 flex">
          <!-- <zondicon icon="shopping-cart" class="h-6 ml-1 fill-current"/> -->
          <p class="sm:hidden ml-5">Summary</p>
          <p class="hidden sm:inline-block md:hidden ml-5">Order Summary</p>
          <p class="hidden md:inline-block ml-5">Show Order Summary</p>
          <zondicon
            v-if="!showMobileCheckoutBreakdown"
            icon="cheveron-down"
            class="ml-3 h-6 fill-current"
          />
          <zondicon
            v-if="showMobileCheckoutBreakdown"
            icon="cheveron-up"
            class="ml-3 h-6 fill-current"
          />
        </div>
        <!-- <div class="flex font-bold right-0">

        <p v-if="state == 'TN' && !availableForPayment && courseCost >  0" class="">$ 0</p>
        <p v-else-if="courseCost == 0" class="">$ 0</p>
        <p v-else class="">$ {{ payFull ? totalPrice : depositAmount }}</p>
      </div> -->
      </div>
    </div>
    <!-- End Table Toggle Button -->

    <div v-if="showMobileCheckoutBreakdown" class="-mx-8">
      <table class="w-full mb-10 text-gray-700">
        <!-- <tbody v-if="state == 'TN' && !availableForPayment && courseCost > 0">
        <tr class="border-b border-red-200">
          <td class="border-r border-red-200 px-4 py-2">Deposit</td>
          <td class="px-4 py-2"><span class="mr-1">$</span>{{ 100 }}</td>
        </tr>
        <tr class="bg-pink-200 text-pink-900 border-2 border-pink-300 font-semibold">
          <td class="border-r border-pink-300 border px-4 py-2">Due Before Class Starts</td>
          <td class="px-4 py-2"><span class="mr-1">$</span>{{ totalPrice - 100 }}</td>
        </tr>
      </tbody> -->
        <tbody v-if="courseCost == 0">
          <tr class="border-b border-red-200">
            <td class="border-r border-red-200 px-4 py-2">Total</td>
            <td class="px-4 py-2"><span class="mr-1">$</span>0</td>
          </tr>
        </tbody>
        <!--
      <price-table-il v-else-if="state =='IL' && (courseType !== 'IV' && courseType !== 'EKG')"
        :insuranceCost="insuranceCost"
        :payFull="payFull"
        :payCustom="payCustom"
        :payCustomAmount="payCustomAmount"
        :courseCost="courseCost"
        :name="name"
        :totalPrice="totalPrice"
        :depositAmount="depositAmount"
        :remainingBalance="remainingBalance"
        :hasNationalExam="hasNationalExam"
        :examFeeCost="examFeeCost"
        :discountCode="discountCode"
      >
        <template v-slot:coupon-breakdown="{discountCode}">
          <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
            <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon Tuition</td>
            <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
          </tr>
        </template>
      </price-table-il>
      -->
        <!--
      <price-table-tn v-else-if="state =='TN' && (courseType !== 'IV' && courseType !== 'EKG')"
      :insuranceCost="insuranceCost"
      :payFull="payFull"
      :payCustom="payCustom"
      :payCustomAmount="payCustomAmount"
      :courseCost="courseCost"
      :name="name"
      :totalPrice="totalPrice"
      :depositAmount="depositAmount"
      :remainingBalance="remainingBalance"
      :hasNationalExam="hasNationalExam"
      :examFeeCost="examFeeCost"
      :availableForPayment="availableForPayment"
      :with-exam-fee="withExamFee"
      :application-fee="tnApplicationFee"
      :pay-selected="paySelected"
      :discountCode="discountCode"
    >
      <template v-slot:coupon-breakdown="{discountCode}">
        <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon Tuition</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
        </tr>
      </template>
    </price-table-tn>
    -->
        <price-table-ptb
          v-else-if="courseType !== 'IV' && courseType !== 'EKG'"
          :insuranceCost="insuranceCost"
          :externship-cost="externshipCost"
          :payFull="payFull"
          :payCustom="payCustom"
          :payCustomAmount="payCustomAmount"
          :courseCost="courseCost"
          :name="name"
          :totalPrice="totalPrice"
          :depositAmount="depositAmount"
          :remainingBalance="remainingBalance"
          :hasNationalExam="hasNationalExam"
          :examFeeCost="examFeeCost"
          :discountCode="discountCode"
          :discountCodeAmount="discountCodeAmount"
          :paySelected="paySelected"
          :state="state"
          :with-exam-fee="withExamFee"
          :availableForPayment="availableForPayment"
          :applicationFee="tnApplicationFee"
          :state_fee_items="state_fee_items"
        >
          <template v-slot:coupon-breakdown="{ discountCode }">
            <tr
              v-if="discountCode.isValid"
              class="border-2 text-green-900 bg-green-100 font-semibold"
            >
              <td
                class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
              >
                Coupon "{{ discountCode.name }}"
              </td>
              <td
                class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
              >
                -$ {{ discountCode.amount }}
              </td>
            </tr>
          </template>
          <template v-slot:total-coupon-breakdown="{ discountCode }">
            <tr v-if="discountCode.isValid" class="border-2 font-semibold">
              <td class="px-4 py-2">Coupon "{{ discountCode.name }}"</td>
              <td class="px-4 py-2">-$ {{ discountCode.amount }}</td>
            </tr>
          </template>
          <template
            v-slot:total-coupon-payment="{
              discountCode,
              totalAmount,
              discountCodeAmount,
              paySelected,
              onlyTN,
            }"
          >
            <tr
              v-if="discountCode.isValid && paySelected == 'payFull' && !onlyTN"
              class="border-2 text-green-900 bg-green-100 border-2 font-semibold"
            >
              <td
                class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
              >
                Total Payment Today
              </td>
              <td
                class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
              >
                $ {{ totalAmount - discountCodeAmount }}
              </td>
            </tr>
          </template>
        </price-table-ptb>

        <price-table-iv
          v-else-if="courseType === 'IV'"
          :insuranceCost="insuranceCost"
          :payFull="payFull"
          :payCustom="payCustom"
          :payCustomAmount="payCustomAmount"
          :courseCost="courseCost"
          :name="name"
          :totalPrice="totalPrice"
          :depositAmount="depositAmount"
          :remainingBalance="remainingBalance"
          :hasNationalExam="hasNationalExam"
          :examFeeCost="examFeeCost"
          :discountCode="discountCode"
          :state_fee_items="state_fee_items"
        >
          <template v-slot:coupon-breakdown="{ discountCode }">
            <tr
              v-if="discountCode.isValid"
              class="border-2 text-green-900 bg-green-100 font-semibold"
            >
              <td
                class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
              >
                Coupon Tuition
              </td>
              <td
                class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
              >
                -$ {{ discountCode.amount }}
              </td>
            </tr>
          </template>
        </price-table-iv>

        <price-table-ekg
          v-else-if="courseType === 'EKG'"
          :insuranceCost="insuranceCost"
          :payFull="payFull"
          :payCustom="payCustom"
          :payCustomAmount="payCustomAmount"
          :courseCost="courseCost"
          :name="name"
          :totalPrice="totalPrice"
          :depositAmount="depositAmount"
          :remainingBalance="remainingBalance"
          :hasNationalExam="hasNationalExam"
          :examFeeCost="examFeeCost"
          :discountCode="discountCode"
        >
          <template v-slot:coupon-breakdown="{ discountCode }">
            <tr
              v-if="discountCode.isValid"
              class="border-2 text-green-900 bg-green-100 font-semibold"
            >
              <td
                class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
              >
                Coupon Tuition
              </td>
              <td
                class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
              >
                -$ {{ discountCode.amount }}
              </td>
            </tr>
          </template>
        </price-table-ekg>

        <!--
    <price-table-all v-else-if="courseType !== 'IV' && courseType !== 'EKG'"
      :insuranceCost="insuranceCost"
      :payFull="payFull"
      :payCustom="payCustom"
      :payCustomAmount="payCustomAmount"
      :courseCost="courseCost"
      :name="name"
      :totalPrice="totalPrice"
      :depositAmount="depositAmount"
      :remainingBalance="remainingBalance"
      :hasNationalExam="hasNationalExam"
      :examFeeCost="examFeeCost"
      :discountCode="discountCode"
    >
      <template v-slot:coupon-breakdown="{discountCode}">
        <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon Tuition</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
        </tr>
      </template>
    </price-table-all>
    -->
      </table>
      <!--    <p class="mx-md-6 mx-0 mt-0 text-center" v-if="state =='TN' || state =='IL'">-->
      <!--      Fees with <span class="font-bold text-xl">*</span> are due before the first day of class.-->
      <!--    </p>-->
    </div>
    <div v-if="!showMobileCheckoutBreakdown" class="-mx-8">
      <table class="w-full mb-2 text-gray-700" v-if="state !== 'TN'">
        <tbody>
          <tr
            v-if="payFull && !payCustom"
            class="'text-green-900 bg-green-100 font-semibold'"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Tuition & Exam Fee
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              $ {{ totalPrice }}
            </td>
          </tr>

          <tr
            v-if="!payFull && !payCustom"
            class="'text-green-900 bg-green-100 font-semibold'"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Registration Only
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              $ {{ depositAmount }}
            </td>
          </tr>

          <tr
            v-if="payCustom"
            class="'text-green-900 bg-green-100 font-semibold'"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Your Payment
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              $ {{ payCustomAmount }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="w-full mb-2 text-gray-700" v-else>
        <tbody>
          <!-- <tr v-if="payFull && !payCustom" class="'text-green-900 bg-green-100 font-semibold'">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Total for Tuition & Exam Fee</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ totalPrice }}</td>
        </tr>

        <tr v-if="!payFull && !payCustom" class="'text-green-900 bg-green-100 font-semibold'">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Registration Only</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ depositAmount }}</td>
        </tr>

        <tr v-if="payCustom" class="'text-green-900 bg-green-100 font-semibold'">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Your Payment</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ payCustomAmount }}</td>
        </tr> -->
          <tr :class="['text-green-900 bg-green-100 font-semibold']">
            <td
              class="border-t-2 border-l-2 border-green-400 px-4 py-2"
              :class="[paySelected === 'payFull' ? 'border-b-2' : '']"
            >
              Total Payment Today
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
              v-if="paySelected === 'payFull'"
            >
              $ {{ totalPayment + tnApplicationFee }}
            </td>
            <td
              class="border-t-2 border-r-2 border-green-400 px-4 py-2"
              v-else-if="paySelected === 'payNotFull'"
            >
              $ {{ depositAmount + tnApplicationFee }}
            </td>
            <td
              class="border-t-2 border-r-2 border-green-400 px-4 py-2"
              v-else-if="paySelected === 'payCustom'"
            >
              $ {{ payCustomAmount + tnApplicationFee }}
            </td>
            <td class="border-t-2 border-r-2 border-green-400 px-4 py-2" v-else>
              $ {{ tnApplicationFee }}
            </td>
          </tr>
          <tr
            :class="['text-red-900 bg-red-100 font-semibold']"
            v-if="paySelected !== 'payFull'"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2"
            >
              Remaining Balance
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
            >
              $ {{ totalRemainingBalance }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <p class="-mx-8 w-full bg-red-100 text-red-900 font-semibold border-2 mt-1 px-2">
      Effective January 1, 2022, students will be required to be paid in FULL prior to the first day of class.
    </p> -->
    <div class="-mx-8">
      <table class="w-full mb-2">
        <tbody>
          <tr class="'text-green-900 bg-green-100 font-semibold'">
            <td class="border-2 bg-red-100 text-red-900 px-2 font-semibold">
              Students will be required to be paid in full at the half-way point
              of class.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import Zondicon from "vue-zondicons";

import PriceTableIL from "./PriceTable/PriceTableIL";
import PriceTableTN from "./PriceTable/PriceTableTN";
//import PriceTableAll from './PriceTable/PriceTableAll';
import PriceTableIV from "./PriceTable/PriceTableIV";
import PriceTableEKG from "./PriceTable/PriceTableEKG";
import PriceTablePTB from "./PriceTable/PriceTablePTB";
import CourseCoupon from "./CourseCoupon.vue";

export default {
  components: {
    zondicon: Zondicon,
    "price-table-il": PriceTableIL,
    "price-table-tn": PriceTableTN,
    "price-table-ptb": PriceTablePTB,
    "price-table-iv": PriceTableIV,
    "price-table-ekg": PriceTableEKG,
    //'price-table-ca': PriceTableCA,
    CourseCoupon,
  },
  computed: {
    ...mapState("courseInformation", [
      "state",
      "name",
      "courseCost",
      "examFeeCost",
      "insuranceCost",
      "availableForPayment",
      "depositAmount",
      "externshipCost",
      "courseType",
      "withExamFee",
      "tnApplicationFee",
      "discountCode",
      "state_fee_items",
      "state_fee_total",
    ]),
    ...mapGetters("courseInformation", [
      "remainingBalance",
      "totalPrice",
      "isAvailableDiscountCoupon",
      "discountCodeAmount",
      //'courseCost'
    ]),
    ...mapState("formData", [
      "payFull",
      "payCustom",
      "payCustomAmount",
      "hasNationalExam",
      "paySelected",
      "showMobileCheckoutBreakdown",
    ]),
    totalPayment() {
      if (this.withExamFee) {
        return (
          this.totalPrice +
          this.examFeeCost +
          (!this.availableForPayment ? this.applicationFee : 0)
        );
      }
      return (
        this.totalPrice + (!this.availableForPayment ? this.applicationFee : 0)
      );
    },
    totalRemainingBalance() {
      // if (this.withExamFee) {
      //   return this.remainingBalance + this.examFeeCost;
      // }
      return this.remainingBalance;
    },
  },
  methods: {
    ...mapMutations("formData", ["updateShowMobileCheckoutBreakdown"]),
    onOpen() {
      this.updateShowMobileCheckoutBreakdown(!this.showMobileCheckoutBreakdown);
      //this.open = !this.open;
    },
  },
};
</script>

<style scoped></style>
