<template>
  <div class="text-red-800">
    <h3
      v-if="envelopeId"
      class="text-2xl sm:text-3xl font-normal leading-normal mt-2"
      :class="{
        ...formCssClasses('paragraph'),
      }"
    >
      You're almost done!
    </h3>

    <h3
      v-if="!envelopeId"
      class="text-2xl sm:text-3xl font-normal leading-normal mt-2"
      :class="{
        ...formCssClasses('paragraph'),
      }"
    >
      You're almost done!
    </h3>
    <h3
      v-if="!envelopeId"
      class="text-xl sm:text-2xl font-normal leading-normal mt-2"
      :class="{
        ...formCssClasses('paragraph'),
      }"
    >
      Your registration has been received.
    </h3>

    <div class="mb-12">
      <!-- <p
      v-if="envelopeId && !isAccountCreated" -->
      <p
        v-if="false"
        class="mt-8 font-bold text-lg"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        Please click
        <a
          class="hover:text-blue-700 text-blue-500 underline font-bold"
          :href="link"
          target="_blank"
        >
          THIS LINK
        </a>
        to confirm your Registration AND to sign your paperwork. NOTE: Your seat
        will not be saved until your paperwork has been completed.
      </p>
      <p
        class="mt-2 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        We’ve got your application! To finalize your registration and add you to
        the class roster, we need two things from you.
      </p>
      <p
        class="mt-1 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        First, create a Password and Log into your Student Portal.
      </p>
      <p
        class="mt-1 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        Here, you will complete your Student Profile and Enrollment Paperwork
        via Docusign.
      </p>
      <p
        class="mt-1 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
        v-if="gradDocRequired"
      >
        Second, upload proof of graduation.
      </p>
      <p
        class="mt-1 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
        v-if="gradDocRequired"
      >
        We can accept college or high school transcripts<span v-if="state !== 'NC'"> , or transcripts of
        equivalency</span>.
      </p>
      <p
        class="mt-1 font-bold text-md"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        When these things have been completed, congrats! You are officially on
        our roster and ready to start class.
      </p>
      <hr class="border-red-300 mt-5" />
      <!-- Create account form -->
      <form-create-account v-if="!isAccountCreated" />
      <p
        v-if="envelopeId && !isAccountCreated"
        class="font-bold text-lg"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        Please enter a password to create your Student Account
      </p>
      <p
        v-if="!envelopeId && !isAccountCreated"
        class="mt-0 font-bold text-lg"
        :class="{
          ...formCssClasses('paragraph'),
        }"
      >
        Please enter a password to create your Student Account
      </p>
      <!-- End Create account form -->

      <created-account-message v-if="isAccountCreated" />
      <!-- <p v-if="envelopeId" class="mt-8 font-bold text-lg text-red-900">
      Your Student Account has been created <br> Please click
      <a class="hover:text-blue-700 text-blue-500 underline font-bold" :href="link" target="_blank">THIS LINK</a> to confirm your Registration AND to sign your paperwork. NOTE: Your seat will not be saved until your paperwork has been completed.
    </p>

    <p v-if="!envelopeId" class="mt-8 font-bold text-lg text-red-900">
      Your Student Account has been created <br> Please click
      <a class="hover:text-blue-700 text-blue-500 underline font-bold" :href="link" target="_blank">THIS LINK</a> to confirm your account.
    </p> -->

      <div v-if="false">
        <p
          class="mt-8"
          :class="{
            ...formCssClasses('paragraph'),
          }"
        >
          <span v-if="envelopeId">
            You will also be able to
          </span>
          <span v-if="!envelopeId">
            This is where you will be able to
          </span>

          <span class="font-bold">manage your classes & make payments.</span>
        </p>
        <p
          class="mt-8 text-red-900"
          :class="{
            ...formCssClasses('paragraph'),
          }"
        >
          Please be sure to
          <span class="font-bold">
            check your student information
          </span>
          & make sure it is all entered correctly. Your name on your
          <span class="font-bold">
            certificate will appear EXACTLY as it is entered
          </span>
          here.
        </p>

        <div class="flex justify-center pt-6 ">
          <div class="">
            <div
              class="w-full p-6 bg-red-200 text-red-900 border-red-400 border rounded-lg "
            >
              <div class="flex">
                <div class="mr-6">
                  <p class="font-bold">Class</p>
                  <p class="font-bold">Dates</p>
                  <p class="font-bold">Location</p>
                  <p class="font-bold">Time</p>
                </div>
                <div>
                  <!-- type.course_type standard or National add Phlebotomy label else empty
                IV or EKG "IV/EKG Training"
                Standard or National "Standard/National Phlebotomy Training"
               -->
                  <p>{{ getSuccessTitleTrainingCourse }}</p>
                  <p>
                    {{ dateStart | moment("L") }} - {{ dateEnd | moment("L") }}
                  </p>
                  <p>{{ city }}</p>
                  <p>{{ formattedTimes }}</p>
                </div>
              </div>

              <div>
                <p class="mt-5 -mb-4 text-sm">
                  If this information is wrong call
                  <span class="font-bold">{{ phlebsPhoneNumber }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <p
          class="mt-8 text-sm"
          :class="{
            ...formCssClasses('paragraph'),
          }"
        >
          Our staff will be in touch with you by Email and Text Message between
          now and when class starts.
        </p>

        <p
          class="mt-12 text-lg text-red-900"
          :class="{
            ...formCssClasses('paragraph'),
          }"
        >
          Thank you. We look forward to seeing you soon!
        </p>

        <div>
          <success-message-sidebar
            class="mt-10 lg:hidden"
            color-text="text-red-900"
            color-text-bold="text-red-900"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FormCreateAccount from "./FormCreateAccount.vue";
import CreatedAccountMessage from "./Elements/CreatedAccountMessage";
import SuccessMessageSideBar from "@/components/Checkout/SideBar/Elements/SuccessMessageSideBar";

export default {
  components: {
    "success-message-sidebar": SuccessMessageSideBar,
    FormCreateAccount,
    CreatedAccountMessage,
  },
  computed: {
    ...mapState(["phlebsPhoneNumber"]),
    ...mapState("responseData", [
      "link",
      "enrollmentId",
      "studentId",
      "dateStart",
      "dateEnd",
      "timeStart",
      "timeEnd",
      "city",
      "envelopeId",
    ]),
    ...mapState("courseInformation", [
      "formattedTimes",
      "courseType",
      "isAccountCreated",
      "state",
      "gradDocRequired"
    ]),
    ...mapGetters(["formCssClasses"]),
    getSuccessTitleTrainingCourse() {
      if (this.courseType == "IV") {
        return `IV Training`;
      } else if (this.courseType == "EKG") {
        return `EKG Training`;
      } else {
        return `${this.courseType} Phlebotomy Training`;
      }
    },
  },
};
</script>
