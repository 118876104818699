<template>
  <div>
    <h3 class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2 text-gray-800">
      Pre-Enrollment Checklist
    </h3>
    <hr class="border-red-300 mt-5">
    <p class="text-xs md:text-base mt-6">
      The Tennessee Higher Education Commission requires all students to complete a Pre-Enrollment checklist prior to enrollment.
      Please enter your initials and signature where required.
    </p>
    <p class="text-xs md:text-base my-3">
      Toured the institution:
    </p>
    <label
      v-for="(tour, i) in tourTypes"
      :key="i"
      class="text-gray-800 text-md font-bold block mb-2"
    >
      <input
        type="checkbox"
        class="form-checkbox mr-0 h-6 w-6 focus:outline-red-100"
        :class="{ ...formCssClasses('checkbox') }"
        :checked="tour === 'Virtually' ? true : false"
        disabled
      >
      <span class="text-xs md:text-sm">
        {{ tour }}
      </span>
    </label>
    <validation-observer ref="formTNChecklist" v-slot="{handleSubmit}">
      <tn-checklist-item
        v-for="(item, index) in checks"
        :key="index"
        :item="item"
        :sign="tnChecklist[item.name]"
        :on-check="onCheckItem"
        :get-initials="getInitials"
      >
      </tn-checklist-item>
      <div class="flex justify-around mt-2 pt-6" style="border-bottom: 1px solid black;">
        <div class="text-center self-stretch" >
          <div class="bg-yellow-300 cursor-pointer p-4"
            @click="onSign"
            v-if="!studentSignature"
          >
            <p class="cursor-pointer text-xs md:text-base" > Sign </p>
            <zondicon
            icon="arrow-thick-down"
            class="h-5 ml-1"/>
          </div>
          <div  v-if="studentSignature">
            <p class="student-signature text-xl md:text-2xl"> {{ studentSignature }} </p>
          </div>

        </div>
        <div class="text-center self-end">
          <p class="text-xs md:text-base">{{ new Date() | moment("dddd, MMMM Do YYYY") }}</p>
        </div>
      </div>
      <validation-provider name="studentSignature" v-slot="{ errors }">
        <input type="hidden" v-model="studentSignature">
        <p class="mt-1 ml-1 text-red-500 text-xs md:text-sm font-semibold italic">{{ errors[0]}}</p>
      </validation-provider>
      <next-back-buttons v-on:next="handleSubmit(onSubmit)" v-on:back="back">
      </next-back-buttons>
    </validation-observer>

  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';

  import NextBackButtons from './Elements/NextBackButtons.vue';
  import TNChecklistItem from './Elements/TNChecklistItem.vue';
  import { InputFacade } from 'vue-input-facade';
  import { ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
  import { ValidationObserver } from 'vee-validate';
  import Zondicon from 'vue-zondicons';

  export default {
    data: () => ({
      checks: [
        {
          name: 'item1',
          text: 'Received an institutional catalog and if provided electronically understands that the student may request a hard-copy of the catalog at any time;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item2',
          text: 'Was given the time and opportunity to review the institutional policies in the catalog;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item3',
          text: 'Knows the length of the program for full-time and part-time students in academic terms and actual calendar time;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item4',
          text: 'Has been informed of the total tuition and other fees of the program;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item5',
          text: 'Has been informed of the estimated cost of books and any required equipment purchases such as a computer, specialized tools, art supplies;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item6',
          text: 'Has been given a copy of the institutional refund policy;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item7',
          text: 'Has executed a Transfer of Credits Disclosure statement in compliance with Tenn. Code Ann. § 49-7-14 and understands the specific limitations should the institution have articulation agreements;',
          hasExtraText: false,
          extraText: '',
        },
        {
          name: 'item8',
          text: 'Understands any person claiming damage or loss as a result of any act or practice by this institution that is a violation of the Title 49, Chapter 7, Part 20 or Rule Chapter 1540-01-02 may file a complaint with the Tennessee Higher Education Commission, Division of Postsecondary State Authorization (DPSA). DPSA’s address is 404 James Robertson Parkway, Parkway Towers Suite 1900, Nashville, TN 37243 and its telephone number is (615) 741-5293.',
          hasExtraText: false,
          extraText: '',
        },
        // {
        //   name: 'item9',
        //   text: 'Student has toured the institution.',
        //   hasExtraText: false,
        //   extraText: '',
        // },
        {
          name: 'item10',
          text: 'For the program entitled, Phlebotomy Training, I have been informed that for the July 2019/July 2020 period, the withdrawal rate is 3 % , the completion rate is 96%, and the in - field placement rate is 5%. Detailed statistical data for this program may be viewed by going to https://www.tn.gov/thec/bureaus/student-aid-and-compliance/postsecondary-state-authorization authorized-institutions-and-data/institutions-l-p.html',
          hasExtraText: false,
          extraText: '',
        },
      ],
      tourTypes: ['In-person', 'Virtually', 'Not applicable because the institution provides only distance education'],
      initials: 'Initials',
      fullNameSignature: '',
    }),
    components:{
      NextBackButtons,
      'tn-checklist-item': TNChecklistItem,
      'zondicon': Zondicon,
      'input-facade': InputFacade,
      'validation-provider': ValidationProvider,
      'validation-observer' : ValidationObserver
    },
    mounted(){
      this.fullNameSignature = `${this.name} ${this.lastName}`;
      // console.log(this.studentSignature);
      // if(this.studentSignature){
      //   this.studentSignature = `${this.name} ${this.lastName}`;
      // }
      //this.studentSignature = `${this.name} ${this.lastName}`;
    },
    computed:{
      ...mapState('formData',['tnChecklist','name','lastName']),
      ...mapGetters(['formCssClasses']),
      getInitials(){
        let fullName = this.name + " " + this.lastName;
        return fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).join('').toUpperCase();
      },
      studentSignature: {
        get(){
          return this.tnChecklist.studentSignature;
        },
        set(value){
          this.updateTNChecklistItem({
            field: 'studentSignature',
            value,
          });
        },
      },
    },
    methods:{
      ...mapMutations('formData',['updateTNChecklistItem']),
      onCheckItem(field, value){
        this.updateTNChecklistItem({ field, value });
      },
      onSign(){
        this.studentSignature = this.fullNameSignature;
        this.studentSignatureSign = true;
      },
      onSignInitials(field){
        const fieldSigned = `${field}Sign`
        // console.log(fieldSigned);
        this[field] = true;
        this[fieldSigned] = true;
      },
      returnModel(model){
        //this.$nextTick(() => {
          return this[model];
        //})

      },
      async onSubmit() {

        try {
          const { fields } = this.$refs.formTNChecklist;

          for (const field in fields) {
            const value = this.tnChecklist[field];
            // console.log(value);
            if(!value && field !== 'studentDisclosureSignature'){
              await this.$refs.formTNChecklist.setErrors({
                [field]: ['This field must be signed'],
              });
              return;
            }
          }

          this.$emit('next');


        } catch (error) {
          console.log(error);
        }

    },
      back(){
        this.$emit('back');
      },
    }
  }
</script>

<style scoped>
  p.student-signature{

    font-family: "Cedarville Cursive";


  }
  span.student-signature{
    font-family: "Cedarville Cursive";
    /*font-size:12pt;*/
  }
  .cursor-pointer{
    cursor: pointer;
  }
</style>