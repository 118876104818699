<template>
	<div>
		<h3
			class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2"
			:class="{
				...formCssClasses('label'),
			}"
		>
			Address Information
		</h3>

		<hr
			class="mt-5"
			:class="{
				...formCssClasses('hr'),
			}"
		/>
		<!-- <validation-observer v-slot="{ handleSubmit}"> -->
		<!-- Address -->
		<div class="flex mt-8">
			<div class="w-full mb-3 pt-0">
				<label
					class="hidden sm:block text-md font-bold ml-2 mb-2"
					:class="{
						...formCssClasses('label'),
					}"
				>
					Address
				</label>

				<vue-google-autocomplete
					id="map"
					ref="addressInput"
					placeholder="Start typing address"
					country="US"
					@placechanged="getAddressData"
					@change="changeAction"
					@inputChange="manualChange"
					classname="px-3 py-4 relative bg-white rounded text-base shadow focus:outline-red-200 w-full"
					:class="{
						'border-2 border-red-500': errors.address,
						...formCssClasses('input'),
					}"
				/>

				<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
					{{ errors.address }}
				</p>
			</div>
		</div>
		<!-- End Address -->
		<!-- Address 2 -->
		<div class="flex mt-4 md:mt-8">
			<div class="w-full md:mb-3 pt-0">
				<label
					class="hidden sm:block text-md font-bold ml-2 mb-2"
					:class="{
						...formCssClasses('label'),
					}"
				>
					Address Field 2 (optional)
				</label>

				<!-- Mobile Version -->
				<input
					type="text"
					v-model="address2"
					placeholder="Address Field 2 (optional)"
					class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
					:class="{
						...formCssClasses('input'),
					}"
				/>
				<!-- End Mobile Version -->

				<!-- Desktop Version -->
				<input
					type="text"
					v-model="address2"
					class="hidden sm:block px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
					:class="{
						...formCssClasses('input'),
					}"
				/>
				<!-- End Desktop Version -->
			</div>
		</div>
		<!-- End Address 2 -->
		<!-- City, State and Zip -->
		<div class="md:flex mt-3 md:mt-8 pb-3">
			<div class="md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0">
				<label
					class="hidden sm:block text-md font-bold ml-2 mb-2"
					:class="{
						...formCssClasses('label'),
					}"
				>
					City
				</label>

				<!-- <validation-provider name="city" rules="required" v-slot="{ errors }"> -->

				<!-- Mobile Version -->
				<input
					type="text"
					:value="city"
					@input="onInput('city', $event)"
					:class="{
						'border-2 border-red-500': errors.city,
						...formCssClasses('input'),
					}"
					placeholder="City"
					class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
				/>
				<!-- End Mobile Version -->

				<!-- Desktop Version -->
				<input
					type="text"
					:value="city"
					@input="onInput('city', $event)"
					:class="{
						'border-2 border-red-500': errors.city,
						...formCssClasses('input'),
					}"
					class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
				/>
				<!-- End Desktop Version -->

				<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
					{{ errors.city }}
				</p>
				<!-- </validation-provider> -->
			</div>

			<div class="md:w-1/6 mb-4 md:mr-6 pt-3 md:pt-0">
				<label
					class="hidden sm:block text-md font-bold ml-2 mb-2"
					:class="{
						...formCssClasses('label'),
					}"
				>
					State
				</label>

				<div class="relative">
					<select
						v-model="state"
						:class="{
							'border-2 border-red-500': errors.state,
							...formCssClasses('input'),
						}"
						class="block appearance-none w-full bg-white py-4 px-4 pr-8 rounded leading-tight focus:outline-none shadow focus:bg-white focus:border-red-900"
						id="grid-state"
					>
						<!-- <option hidden selected disabled value="" class="md:hidden">State</option> -->
						<option v-for="(abbr, i) in statesAbbr" :key="i">
							{{ abbr }}
						</option>
					</select>
					<div
						class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
					>
						<svg
							class="fill-current text-red-900 h-4 w-4"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
						>
							<path
								d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
							/>
						</svg>
					</div>
				</div>
				<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
					{{ errors.state }}
				</p>
			</div>

			<div class="md:w-2/6 mb-3 pt-3 md:pt-0">
				<label
					class="hidden sm:block text-md font-bold ml-2 mb-2"
					:class="{
						...formCssClasses('label'),
					}"
				>
					Zip code
				</label>

				<!-- <validation-provider name="zip code" rules="required|numeric|min:5|max:9" v-slot="{ errors }"> -->

				<!-- Mobile Version -->
				<input
					type="text"
					:value="zip"
					@input="onInput('zip', $event)"
					@keypress="onKeyPress('zip', $event)"
					:class="{
						'border-2 border-red-500': errors.zip,
						...formCssClasses('input'),
					}"
					placeholder="Zip Code"
					class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
				/>
				<!-- <input-facade mask="#####  ####" type="text" v-model="zip" :class="{ 'border-2 border-red-500' : errors.zip }" placeholder="Zip Code" class="sm:hidden px-3 py-4 placeholder-pink-400 text-red-900 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"/> -->
				<!-- End Mobile Version -->

				<!-- Desktop Version -->
				<input
					type="text"
					:value="zip"
					@input="onInput('zip', $event)"
					@keypress="onKeyPress('zip', $event)"
					:class="{
						'border-2 border-red-500': errors.zip,
						...formCssClasses('input'),
					}"
					class="hidden sm:block px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
				/>
				<!-- <input-facade mask="#####  ####" type="text" v-model="zip" :class="{ 'border-2 border-red-500' : errors.zip }"  class="hidden sm:block px-3 py-4 placeholder-red-300 text-red-900 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"/> -->
				<!-- End Desktop Version -->

				<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
					{{ errors.zip }}
				</p>
				<!-- </validation-provider> -->
			</div>
		</div>
		<!-- End City, State and Zip -->
		<!-- State Tracking Fields -->
		<!-- <div v-if="stateTrackingFields.length"> -->
		<div v-if="false">
			<h6
				class="text-base md:text-lg font-semibold mt-2"
				:class="{
					...formCssClasses('paragraph'),
				}"
			>
				The State of {{ courseState.name }} requires students to fill out the
				following questions:
			</h6>
			<hr
				class="mt-1 mb-2"
				:class="{
					...formCssClasses('hr'),
				}"
			/>
			<div
				class="flex flex-col md:flex-row md:mb-2 justify-between"
				v-for="(field, i) in stateTrackingFields"
				:key="i"
			>
				<div class="mb-2 md:mr-3 flex-initial">
					<label
						class="block text-base md:text-lg font-normal"
						:class="{
							...formCssClasses('label'),
						}"
					>
						{{ field.checkout_label }}
						<span v-if="field.required_on_checkout">*</span>
					</label>
				</div>
				<div class="mb-3 flex-none">
					<!-- text input -->
					<div v-if="field.value_type === '[input_text]'">
						<input
							type="text"
							placeholder=""
							class="p-3 w-full bg-white rounded text-base shadow outline-none focus:outline-red-200"
							:class="{
								...formCssClasses('input'),
							}"
							@input="onInputTrackingField(field.id, $event)"
							:value="getTrackingFieldValue(field.id)"
						/>
						<p
							class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
							v-if="field.required_on_checkout"
						>
							{{ trackingErrors[field.id] }}
						</p>
					</div>
					<!-- end text input -->
					<!-- input_textarea -->
					<div v-if="field.value_type === '[input_textarea]'">
						<textarea
							class="p-3 resize-none w-full bg-white rounded text-base shadow outline-none focus:outline-red-200"
							:class="{
								...formCssClasses('input'),
							}"
							@input="onInputTrackingField(field.id, $event)"
							:value="getTrackingFieldValue(field.id)"
						/>
						<p
							class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
							v-if="field.required_on_checkout"
						>
							{{ trackingErrors[field.id] }}
						</p>
					</div>
					<!-- end input_textarea -->
					<!-- date picker -->
					<div v-if="field.value_type === '[date_picker]'">
						<date-picker
							format="YYYY-MM-DD"
							type="date"
							placeholder="Select date"
							:clearable="true"
							:editable="false"
							:input-class="`mx-input`"
							@change="onDatePicker(field.id, $event)"
							valueType="format"
							:value="getTrackingFieldValue(field.id)"
						></date-picker>
						<p
							class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
							v-if="field.required_on_checkout"
						>
							{{ trackingErrors[field.id] }}
						</p>
					</div>
					<!-- end date picker -->
					<!-- multiple choice -->
					<div
						v-if="
							field.value_type === '[multiple_choice]' && field.options.length
						"
						class="flex flex-col"
					>
						<div
							v-for="(option, i) in field.options"
							:key="i"
							class="pb-1 whitespace-nowrap"
						>
							<label>
								<input
									type="radio"
									class="form-radio mr-0 h-6 w-6 focus:outline-red-100"
									:class="{
										...formCssClasses('radio_button'),
									}"
									:name="field.column_name"
									:value="option"
									@change="
										onChangeTrackingField(field.id, 'multiple_choice', $event)
									"
								/>
								<span
									class="text-sm md:text-base font-normal"
									:class="{
										...formCssClasses('label'),
									}"
								>
									{{ option }}
								</span>
							</label>
						</div>
						<p
							class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
							v-if="field.required_on_checkout"
						>
							{{ trackingErrors[field.id] }}
						</p>
					</div>
					<!-- end multiple choice -->
					<!-- multiple answer -->
					<div
						v-if="
							field.value_type === '[multiple_answer]' && field.options.length
						"
						class="flex flex-col"
					>
						<div v-for="(option, i) in field.options" :key="i" class="pb-1">
							<label class="block">
								<input
									type="checkbox"
									class="form-checkbox mr-0 h-6 w-6 focus:outline-red-100"
									:class="{
										...formCssClasses('checkbox'),
									}"
									:name="field.column_name"
									:value="option"
									@change="
										onChangeTrackingField(field.id, 'multiple_answer', $event)
									"
								/>
								<span
									class="text-sm md:text-base font-normal"
									:class="{
										...formCssClasses('label'),
									}"
								>
									{{ option }}
								</span>
							</label>
						</div>
						<p
							class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
							v-if="field.required_on_checkout"
						>
							{{ trackingErrors[field.id] }}
						</p>
					</div>
					<!-- end multiple answer -->
				</div>
			</div>
		</div>
		<!-- End State Tracking Fields -->
		<!-- How did you hear about us? -->
		<div v-if="false">
			<hr
				class="mb-4"
				:class="{
					...formCssClasses('hr'),
				}"
			/>
			<h6
				class="text-base md:text-lg font-semibold mt-2 mb-4"
				:class="{
					...formCssClasses('paragraph'),
				}"
			>
				How did you hear about us?
			</h6>

			<div class="flex flex-col justify-between">
				<div class="w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0">
					<div class="relative">
						<select
							:value="referredBy"
							@change="onChange('referredBy', $event)"
							:class="{
								'border-2 border-red-500': errors.referredBy,
								...formCssClasses('input'),
							}"
							class="block appearance-none w-full bg-white py-4 px-4 pr-8 rounded leading-tight focus:outline-none shadow focus:bg-white focus:border-red-900"
						>
							<option value="">Select one</option>
							<option
								v-for="opt in referralOpts"
								:key="opt.value"
								:value="opt.value"
							>
								{{ opt.text }}
							</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
						>
							<svg
								class="fill-current text-red-900 h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path
									d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
								/>
							</svg>
						</div>
					</div>
					<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
						{{ errors.referredBy }}
					</p>
				</div>
				<div
					v-if="referredBy === 'other'"
					class="w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0"
				>
					<label
						class="text-red-900 text-md ml-2 mb-2"
						:class="{
							...formCssClasses('label'),
						}"
					>
						Specify below
					</label>
					<input
						type="text"
						:value="commentReferredBy"
						@input="onInput('commentReferredBy', $event)"
						:class="{
							'border-2 border-red-500': errors.commentReferredBy,
							...formCssClasses('input'),
						}"
						placeholder="Please specify"
						class="px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full mt-4"
					/>
					<p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
						{{ errors.commentReferredBy }}
					</p>
				</div>
			</div>
		</div>
		<!-- End How did you hear about us? -->
		<next-back-buttons v-on:next="onSubmit" v-on:back="back">
		</next-back-buttons>
		<!-- </validation-observer> -->
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import NextBackButtons from './Elements/NextBackButtons.vue';
// import { InputFacade } from 'vue-input-facade'
// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
// import { ValidationObserver } from 'vee-validate';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	components: {
		'vue-google-autocomplete': VueGoogleAutocomplete,
		'next-back-buttons': NextBackButtons,
		DatePicker,
		// 'input-facade': InputFacade,
		// 'validation-provider': ValidationProvider,
		// 'validation-observer' : ValidationObserver
	},
	data: () => ({
		time: '',
		addressInput: '',
		statesAbbr: [
			'AL',
			'AK',
			'AZ',
			'AR',
			'CA',
			'CO',
			'CT',
			'DE',
			'FL',
			'GA',
			'HI',
			'ID',
			'IL',
			'IN',
			'IA',
			'KS',
			'KY',
			'LA',
			'ME',
			'MD',
			'MA',
			'MI',
			'MN',
			'MS',
			'MO',
			'MT',
			'NE',
			'NV',
			'NH',
			'NJ',
			'NM',
			'NY',
			'NC',
			'ND',
			'OH',
			'OK',
			'OR',
			'PA',
			'RI',
			'SC',
			'SD',
			'TN',
			'TX',
			'UT',
			'VT',
			'VA',
			'WA',
			'WV',
			'WI',
			'WY',
		],
		errors: {
			address: '',
			city: '',
			state: '',
			zip: '',
		},
		trackingErrors: {},
		fields: [
			'address',
			'city',
			'state',
			'zip',
		],
		tracking: [],
		areRequired: ['address', 'city', 'state', 'zip'],
		trackingAreRequired: [],
	}),
	mounted() {
		//console.log('mounted');
		this.$refs.addressInput.update(this.$store.state.formData.address);
		this.tracking = this.stateTrackingFields.map((item) => item.id);
		// this.trackingAreRequired = this.stateTrackingFields
		// 	.filter((item) => !!item.required_on_checkout)
		// 	.map((item) => item.id);
		// this.trackingAreRequired.forEach((item) =>
		// 	this.$set(this.trackingErrors, item, '')
		// );
		//console.log(this.trackingAreRequired, this.trackingErrors, this.tracking);
	},
	computed: {
		...mapState('courseInformation', [
			/*'stateName',*/ 'stateTrackingFields',
			'courseState',
			'referralOpts',
		]),
		...mapState('formData', ['trackingFields']),
		...mapGetters(['formCssClasses']),
		address: {
			get() {
				return this.$store.state.formData.address;
			},
			set(value) {
				this.$store.commit('formData/updateAddress', value);
			},
		},
		address2: {
			get() {
				return this.$store.state.formData.address2;
			},
			set(value) {
				this.$store.commit('formData/updateAddress2', value);
			},
		},
		city: {
			get() {
				return this.$store.state.formData.city;
			},
			set(value) {
				this.$store.commit('formData/updateCity', value);
			},
		},
		state: {
			get() {
				return this.$store.state.formData.state;
			},
			set(value) {
				this.$store.commit('formData/updateState', value);
			},
		},
		zip: {
			get() {
				return this.$store.state.formData.zip;
			},
			set(value) {
				this.$store.commit('formData/updateZip', value);
			},
		},
		referredBy: {
			get() {
				return this.$store.state.formData.referredBy;
			},
			set(value) {
				this.$store.commit('formData/updateReferredBy', value);
			},
		},
		commentReferredBy: {
			get() {
				return this.$store.state.formData.commentReferredBy;
			},
			set(value) {
				this.$store.commit('formData/updateCommentReferredBy', value);
			},
		},
	},
	methods: {
		...mapMutations('formData', ['setTrackingFields']),
		back() {
			this.$emit('back');
		},
		/**
		 * When the location found
		 * @param {Object} data Data of the found location
		 * @param {Object} placeResult PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData(data, placeResult, id) {
			// Sometimes the street number comes up as undefined
			// we need to handle that "||" is the equivallent
			// to ?: in php
			this.$store.commit(
				'formData/updateAddress',
				(data.street_number || '') + ' ' + data.route
			);
			this.$store.commit('formData/updateCity', data.locality);
			this.$store.commit(
				'formData/updateState',
				data.administrative_area_level_1
			);
			this.$store.commit('formData/updateZip', data.postal_code);
		},
		// Over ride the full address for only street and number
		changeAction(data) {
			// Check that the is the full address whitten in
			//console.log(data);
			//if (data.substr(data.length - 5) == ', USA')
			//{
			const addr = data.split(',')[0];
			//console.log(addr);
			this.$refs.addressInput.update(this.$store.state.formData.address);
			for (const field of this.areRequired) {
				this.errors[field] = '';
			}

			//}
		},
		// If for some reason the address comes bad from google and the user
		// fixes it manualy we need to record that change to prop "address"
		// otherwise that change is not going to get up to the parent
		manualChange({ newVal }) {
			this.errors.address = '';
			if (!newVal) {
				this.errors.address = 'The address field is required';
			}
			this.$store.commit('formData/updateAddress', newVal);
		},
		// forms events
		onInput(field, e) {
			const { value } = e.target;
			//console.log(value.length)
			this.errors[field] = '';

			if (!value && this.areRequired.find((item) => item === field)) {
				this.errors[field] = `The ${field} field is required`;
			} else if (field === 'zip' && (value.length < 5 || value.length > 9)) {
				this.errors[
					field
				] = `The ${field} field must to be greater than 5 and less than 9`;
			}

			if (
				!value &&
				field === 'commentReferredBy' &&
				this.referredBy === 'other'
			) {
				this.errors[field] = `Field is required`;
			}

			this[field] = value;
			//console.log(field, target.value);
		},
		onChange(field, e) {
			const { value } = e.target;
			// console.log(field, value);
			this.errors[field] = '';

			if (!value && this.areRequired.find((item) => item === field)) {
				this.errors[field] = `Field is required`;
			}

			if (value !== 'other') {
				this.commentReferredBy = '';
				this.errors.commentReferredBy = '';
			}
			this[field] = value;
		},
		onKeyPress(field, e) {
			//console.log(e);
			const onlyNumbers = /^[0-9]+$/;

			if (field === 'zip' && !onlyNumbers.test(e.key)) {
				e.preventDefault();
			}
		},
		onDatePicker(id, value) {
			//console.log(id, e);
			const index = this.trackingFields.findIndex((field) => field.id === id);

			if (index < 0) {
				return;
			}

			if (this.trackingErrors.hasOwnProperty(id)) {
				this.trackingErrors[id] = '';
				if (!value && this.trackingAreRequired.find((item) => item === id)) {
					this.trackingErrors[id] = 'This field is required';
				}
			}

			const trackingFields = [...this.trackingFields];
			trackingFields[index].value = value;
			this.setTrackingFields(trackingFields);
		},
		// form tracking fields events
		getTrackingFieldValue(id) {
			const field = this.trackingFields.find((item) => item.id === id);
			if (field) {
				return field.value;
			}
			return '';
		},
		onInputTrackingField(id, e) {
			const index = this.trackingFields.findIndex((field) => field.id === id);

			if (index < 0) {
				return;
			}

			const { value } = e.target;

			if (this.trackingErrors.hasOwnProperty(id)) {
				this.trackingErrors[id] = '';
				if (!value && this.trackingAreRequired.find((item) => item === id)) {
					this.trackingErrors[id] = 'This field is required';
				}
			}

			const trackingFields = [...this.trackingFields];
			trackingFields[index].value = value;
			this.setTrackingFields(trackingFields);

			//console.log(id, value);
		},
		onChangeTrackingField(id, type, e) {
			const index = this.trackingFields.findIndex((field) => field.id === id);

			if (index < 0) {
				return;
			}

			const { value, checked } = e.target;

			const trackingFields = [...this.trackingFields];
			if (type === 'multiple_answer') {
				const values = checked
					? [...trackingFields[index].value, value]
					: trackingFields[index].value.filter((item) => item !== value);
				//console.log(values);
				if (this.trackingErrors.hasOwnProperty(id)) {
					//console.log(values);
					this.trackingErrors[id] = '';
					if (
						!values.length &&
						this.trackingAreRequired.find((item) => item === id)
					) {
						this.trackingErrors[id] = 'This field is required';
					}
				}
				//console.log(values);
				trackingFields[index].value = values;
			} else {
				if (this.trackingErrors.hasOwnProperty(id)) {
					//console.log(values);
					this.trackingErrors[id] = '';
					if (!value && this.trackingAreRequired.find((item) => item === id)) {
						this.trackingErrors[id] = 'This field is required';
					}
				}
				trackingFields[index].value = value;
			}

			this.setTrackingFields(trackingFields);

			//console.log(id, type, value, checked, this.trackingErrors);
		},
		onSubmit() {
			for (const field of this.fields) {
				if (this.errors[field]) {
					return;
				}

				if (!this[field] && this.areRequired.find((item) => item === field)) {
					this.errors[field] =
						field === 'referredBy'
							? 'Field is required'
							: `The ${field} field is required`;
					return;
				}

				if (
					field === 'commentReferredBy' &&
					!this[field] &&
					this.referredBy === 'other'
				) {
					this.errors[field] = `Field is required`;
					return;
				}
			}

			for (const id of this.tracking) {
				if (this.trackingErrors[id]) {
					return;
				}
				const value = this.getTrackingFieldValue(id);
				if (
					(!value || !value.length) &&
					this.trackingAreRequired.find((item) => item === id)
				) {
					this.trackingErrors[id] = 'This field is required';
					//console.log(this.trackingErrors, id);
					return;
				}
			}

			this.$emit('next');
		},
	},
	// data: function () {
	//   return {
	//     addressInput: ""
	//   }
	// },
};
</script>

<style>
.mx-input {
	padding: 24px 14px !important;
	font-size: 1rem;
	color: #742a2a;
	border-radius: 0.25rem;
	border: none;
	box-shadow: 0 0 0 3px #fff5f5;
	outline: 2px solid transparent;
	outline-offset: 2px;
	background-color: #fff;
}

.mx-input-on-error {
	padding: 24px 14px !important;
	font-size: 1rem;
	color: #742a2a;
	border-radius: 0.25rem;
	box-shadow: 0 0 0 3px #fff5f5;
	outline: 2px solid transparent;
	outline-offset: 2px;
	background-color: #fff;
	border-color: #f56565;
	border-width: 2px;
	display: inline-block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	line-height: 1.4;
	-webkit-box-shadow: 0 0 0 3px #fff5f5;
}

.mx-input:focus {
	box-shadow: 0 0 0 3px #fecaca;
}

.mx-input::placeholder {
	color: #f687b3;
}

.mx-btn:hover {
	border-color: #991b1b !important;
	color: #742a2a !important;
}

.mx-datepicker-main {
	font: inherit !important;
	color: #fca5a5 !important;
}

.mx-datepicker {
	width: 100% !important;
}

.mx-btn {
	color: #f87171 !important;
}

.mx-table-date .today {
	color: #991b1b !important;
}

.mx-calendar-content .cell:hover {
	color: #742a2a !important;
	background-color: #fef2f2;
}

.mx-table-date .cell.not-current-month {
	color: #fee2e2 !important;
}

.mx-calendar-content .cell.active {
	background-color: #991b1b !important;
}
</style>
